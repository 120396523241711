input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    accent-color: #8F27C1;
    width: 16.82vw;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    background: linear-gradient(to right,
        #8F27C1 calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%),
        rgba(226, 226, 234, 0.6) calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%));
    border-radius: 14px;
    height: 6px;
    /*accent-color: #8F27C1;*/
  }
  
  input[type='range']::-moz-range-track {
    background: rgba(226, 226, 234, 0.6);
    border-radius: 14px;
    height: 6px;
    accent-color: #8F27C1;
  }
  
  input[type="range"]::-moz-range-progress {
    background-color: #8F27C1;
  }
  
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 100%;
    margin-top: -4.5px;
    background: #ececf2;
    height: 15px;
    width: 15px;
  }
  
  input[type='range']::-moz-range-thumb {
    border-radius: 100%;
    border-color: #8f27c1;
    margin-top: -4.5px;
    background: #ececf2;
    height: 15px;
    width: 15px;
  }
  
  input[type='range']:focus {
    outline: none;
  }
  
  input[type='range']:focus::-webkit-slider-thumb {
    border: 1px solid #8f27c1;
    outline: 3px solid #8f27c1;
    outline-offset: 0.125rem;
    /* box-shadow: -16.82vw 0 0 16.82vw #8F27C1; */
  }
  
  /******** Firefox ********/
  input[type='range']:focus::-moz-range-thumb {
    border: 1px solid #8f27c1;
    outline: 2px solid #8f27c1;
    outline-offset: 0.125rem;
  }